<template>
    <div class="px-3 py-2">
        <b-sidebar aria-labelledby="sidebar-no-header-title" width="80%" no-header id="add_voucher" backdrop style="direction:ltr" right :title="pagename" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" :style="`direction:`+$parent.lang.dir">
                    <div style="width:150px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span style="font-size:.8em;">{{pagename}}</span>
                    </div>
                    <div @click="hide" id="vohclose" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{$parent.lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="px-3 py-2" :style="`direction:`+$parent.lang.dir">
                    <v-form ref="apform">
                        <v-row>
                            <v-col cols="12" md="2" sm="2">
                                <label for="input-live">{{$parent.lang.jvnom}}</label>
                                <b-form-input
                                    id="input-live"
                                    v-model="jvnom"
                                    aria-describedby="input-live-help input-live-feedback"
                                    prepend-icon="mdi-calendar"
                                    :placeholder="$parent.lang.jvnom"   
                                    class="input-sm inborder"
                                ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="4" sm="8">
                                <v-dialog
                                  ref="dialog"
                                  v-model="modal"
                                  :return-value.sync="date"
                                  persistent
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <label for="input-live">{{$parent.lang.date}}</label>
                                    <b-form-input
                                      id="input-live"
                                      v-model="jv_date"
                                      aria-describedby="input-live-help input-live-feedback"
                                      prepend-icon="mdi-calendar"
                                      :placeholder="$parent.lang.date"
                                      readonly
                                      trim
                                      v-bind="attrs"
                                      v-on="on"
                                      class="input-sm inborder"
                                    ></b-form-input>
                                  </template>
                                  <v-date-picker
                                    v-model="jv_date"
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="modal = false"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.dialog.save(jv_date)"
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12" md="4" sm="2"></v-col>
                        </v-row>
                        <v-row>
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th class="text-center" style="width:10%">{{$parent.lang.account}}</th>
                                        <th class="text-center" style="width:40%">{{$parent.lang.acount_name}}</th>
                                        <th class="text-center" style="width:15%">{{$parent.lang.depit}}</th>
                                        <th class="text-center" style="width:15%">{{$parent.lang.credit}}</th>
                                        <th class="text-center" style="width:20%">{{$parent.lang.notes}}</th>
                                        <th class="text-center" style="width:100px">{{$parent.lang.action}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in vouchers" :key="index">
                                        <td>
                                            {{item.classid}}
                                        </td>
                                        <td>
                                            {{item.acount_name}}
                                        </td>
                                        <td>
                                            {{item.depit}}
                                        </td>
                                        <td>
                                            {{item.credit}}
                                        </td>
                                        <td>
                                            {{item.notes}}
                                        </td>
                                        <td>
                                            <b-button variant="danger" class="btn btn-sm" style="width:50px" @click="removeme(item,index)">حذف</b-button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>{{depitPlus}}</th>
                                        <th>{{creditPlus}}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>
                                            <b-form-input @keypress="pressF2('classid')" v-model="newitem.classid" @change="getName()">{{newitem.classid}}</b-form-input>
                                        </th>
                                        <th>
                                            <b-form-input v-model="newitem.acount_name">{{newitem.acount_name}}</b-form-input>
                                        </th>
                                        <th>
                                            <b-form-input v-model="newitem.depit" @change="checkcd('d')">{{newitem.depit}}</b-form-input>
                                        </th>
                                        <th>
                                            <b-form-input v-model="newitem.credit" @change="checkcd('c')">{{newitem.credit}}</b-form-input>
                                        </th>
                                        <th>
                                            <b-form-input v-model="newitem.notes">{{newitem.notes}}</b-form-input>
                                        </th>
                                        <th>
                                            <b-button variant="success" style="width:50px" class="btn btn-sm" @click="addnew()">اضافة</b-button>
                                        </th>
                                    </tr>
                                </tfoot>
                            </v-simple-table>
                        </v-row>
                    </v-form>
                </div>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" variant="success" @click="addit()" class="ma-2" style="width:100px;">{{btnname}}</b-button>
                </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
        <jv-search-class v-if="showSearch" />>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
import JvSearchClass from './jvSearchClass.vue';
export default{
    components: {JvSearchClass},
    data() {
        return {
            modal: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            pagename: this.$parent.lang.add_new_account,
            jv_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            btnname: this.$parent.lang.add,
            jvnom: 1,
            vouchers: [

            ],
            newitem: {
                classid: '',
                acount_name: '',
                credit: '',
                depit: '',
                notes: '',
            },
            showSearch: false
        }
    },
    computed:{
        depitPlus: function(){
            let t = 0;
            for(let i=0;i<this.vouchers.length;i++){
                let v = this.vouchers[i].depit == '' ? 0 : this.vouchers[i].depit;
                t = parseFloat(t) + parseFloat(v);
            }
            return this.$RoundNum(t);
        },
        creditPlus: function(){
            let t = 0;
            for(let i=0;i<this.vouchers.length;i++){
                let v = this.vouchers[i].credit == '' ? 0 : this.vouchers[i].credit;
                t = parseFloat(t) + parseFloat(v);
            }
            return this.$RoundNum(t);
        },

    },
    methods: {
        pressF2(field,e){
            var KeyID = (window.event) ? event.keyCode : e.keyCode;
            // console.log(KeyID);
            if(KeyID == 43){
                if(field == 'classid'){
                    this.showSearch = true;
                }
            }
            event.preventDefault()

        },
        checkcd(id){
            if(id == 'c'){
                if(this.newitem.depit != 0){
                    this.newitem.credit = 0;
                }
            }
            if(id == 'd'){
                if(this.newitem.credit != 0){
                    this.newitem.depit = 0;
                }
            }
        },
        removeme(item,index){
            this.vouchers.splice(index, 1);
        },
        addnew(){
            if
            (
                this.newitem.classid == '' || 
                this.newitem.acount_name == '' || 
                (this.newitem.credit == '' && 
                this.newitem.depit == '')  || 
                (this.newitem.credit == 0 && 
                this.newitem.depit == 0) 
            ){
                return false;
            }
            this.vouchers.push(this.newitem);
            this.newitem = {
                classid: '',
                acount_name: '',
                credit: '',
                depit: '',
                notes: '',
            }
        },
        getName(){
            const post = new FormData();
            post.append("type","getClassname")
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.lang.langname);
            post.append("data[classid]",this.newitem.classid);
            post.append("data[jv]",2);

            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                if(res.results.data.length == 0) {
                    this.newitem.classid = '';
                    return false;
                }
                this.newitem.acount_name = res.results.data.namear;
            })
        },
        getMain(){
            //
        },
        addit() {
            if(this.vouchers.length == 0){
                this.$snotify.error('لا يمكن اضافة قيد  فارغ', 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.depitPlus != this.creditPlus){
                this.$snotify.error('القيد غير متوازن', 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            // else{
            //     this.$snotify.error('تمت اضافة القيد بنجاح', 'تنبيه', {
            //         timeout: 1000000,
            //         showProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         position: SnotifyPosition.centerCenter,
            //         buttons: [
            //             {
            //                 text: this.$parent.lang.close,  
            //                 action: (toast) => {
            //                     this.$snotify.remove(toast.id); 
            //                 } 
            //             },
            //         ]
            //     });
            //     this.vouchers = [];
            //     document.getElementById('vohclose').click();
            //     return false;
            // }
            const post = new FormData();
            post.append("type","addEditJV");
            // console.log(this.$parent.lang.langname);
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.$parent.lang.langname)
            post.append("data[namear]",this.current.namear);
            post.append("data[nameen]",this.current.nameen);
            post.append("data[classid]",this.current.classid);
            post.append("data[parentid]",this.current.parentid);
            post.append("data[account_type]",this.current.account_type);
            post.append("data[type]",this.current.type);
            post.append("data[default]",this.current.default);
            post.append("data[id]",this.current.id);

            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {  
                const res = response.data;          
                
                document.getElementById('dhideExp').click();
                let message = res.results.data;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close,  
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
            })
        }
    },
}
</script>